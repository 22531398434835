.mitarbeiterWrapper {
    display: flex;
    flex-direction: column;
    min-width: 0;
    min-height: 100vh;
    padding: 1rem;
    background-color: #F2F3F5;
    gap: 1rem;
    align-items: center;
    position: relative;
    
}

.mitarbeiterWrapperTitel {
    width: 100%;
    min-width: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mitarbeiterWrapperIcon{
    vertical-align: middle;
    align-items: center;
    display: flex;
    justify-content: left;
    margin-left: 3vw;
}

.mitarbeiterWrapperIcon h1{
    font-size: max(1.7vw, 20px)
}

.mitarbeiterWrapperIcon svg{
    vertical-align: middle;
    min-width : 2rem;
    width: 3vw;
    margin-right: 1rem;
    
}

.mitarbeiterAddButton{
    font-size: 1rem;
    background-color: transparent;
    border: 1px solid;
    border-color: #F2F3F5;
    position: fixed;
    right: 1rem;
}



.mitarbeiterAddButton span{
    margin-right: 1rem;
}

.mitarbeiterAddButton svg:hover{
    cursor: pointer;
    color: green;
}

.mitarbeiterAddButton svg{
    width: 1.5vw;
    vertical-align: middle;   
    min-width : 2rem;
}


.mitarbeiterBox {
    border-radius: 10px;
    background-color: white;
    height: 7vh;
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    gap: 1rem;
    width: 60vw;
}



.mitarbeiterBox:hover {
    cursor: pointer;
    border: 1px solid;
    border-radius: 5px;
    border-color: #b6b6b6;
    box-shadow: 2px 2px #d8d6d6;
}

@keyframes bottomUp {
    from {
        top: 100%;
        transform: translateX(-50%);
    }

    to {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

@keyframes slide-down {
    from {
        top: 50%;
        transform: translate(-50%, -50%);
    }

    to {
        top: 100%;
        transform: translateX(-50%);
    }
}

@keyframes blurr{
    from{opacity: 0%;}
    to{opacity: 0.4;}
}

@keyframes deBlurr{
    from{opacity: 0.4;}
    to{opacity: 0;}
}

.mitarbeiterInfo {
    z-index: 2;
    background-color: white;
    animation: bottomUp .5s;
    animation-fill-mode: forwards;
    min-height: 35rem;
    height: 80vh;
    min-width: 45vw;
    max-width: 100vw;
    position: fixed;
    left: 50%;
    padding: 1rem;
    border-radius: 20px;
    transition: opacity 0.3s ease-out;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.mitarbeiterInfo.hide {
    opacity: 0;
}

.blurrB {
    z-index: 1;
    opacity: 0.4;
    height: 100vh;
    width: 100vw;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    animation: blurr 1s forwards;
}

.blurrB.closing{
    animation: deBlurr 1s forwards;
}

.mitarbeiterInfo.closing {
    animation-name: slide-down;
}

.mitarbeiterInfoHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mitarbeiterInfoHeader button{
    min-width: 2rem;
    max-width: 2rem;
    min-height: 2rem;
    max-height: 2rem;
    border: none;
    padding-left: .7rem;
    padding-right: .7rem;
    border-radius: 50%;
    font-size: 1rem;   
}

.closeButton:hover{
    cursor: pointer;
    background-color:#BD0909;
    color: #ffffff;
}

.mitarbeiterInfoContent{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.mitarbeiterInfoRow{
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
     gap: 1rem;
     width: 100%;
}

.mitarbeiterInfoValue{
    font-size: .9rem;
}

.mitarbeiterInfoKrankenkasse{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 1rem;
}

.mitarbeiterInfoKontaktdaten{
display: flex;
flex-direction: column;
text-align: left;
gap: 1rem;
}

.mitarbeiterRowSeperator{
    border: 1px solid;
    opacity: .1;
    border-color: #919191;
    width: 100%;
}

.mitarbeiterInfoTextArea{
    margin-top: 1rem;
    text-align: left;
    height: 100%;
}

.mitarbeiterInfoTextArea textarea{
    resize: none;
    width: 100%;
    height: 100%;
}

.mitarbeiterInfoFooter{
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
}

.mitarbeiterInfoButton{
    border: none;
    padding-left: .7rem;
    padding-right: .7rem;
    font-size: 1rem;
    background-color: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: white;
    text-align: center;
}

.mitarbeiterInfoButton svg{
    vertical-align: middle;
    width: 1.5vw;
    margin-right: 1rem;
    color: #919191;
}

.mitarbeiterInfoButton:hover{
    border: 1px solid;
    border-radius: 5px;
    border-color: #b6b6b6;
    box-shadow: 0px 2px #d8d6d6;
    cursor: pointer;
}

.mitarbeiterEdit{
    z-index: 2;
    background-color: white;
    animation: bottomUp .5s;
    animation-fill-mode: forwards;
    min-height: 35rem;
    height: 80vh;
    min-width: 45vw;
    max-width: 100vw;
    position: fixed;
    left: 50%;
    padding: 1rem;
    border-radius: 20px;
    transition: opacity 0.3s ease-out;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.mitarbeiterEdit.closing{
    animation-name: slide-down;
}

.mitarbeiterInfoRow input{
    border: none;
    background-color:#e9e9e9;
    padding: .3rem;
    border-radius: 10px;
}

.mitarbeiterInfoRow textarea{
    border: none;
    background-color:#e9e9e9;
    padding: .3rem;
    border-radius: 10px;
    resize:none;
    width: 100%;
    height: 100%;
}

.mitarbeiterEditSubmit{
    border: none;
    padding-left: .7rem;
    padding-right: .7rem;
    font-size: 1rem;
    background-color: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: white;
}

.mitarbeiterEditSubmit svg{
    vertical-align: middle;
    width: 1.5vw;
    margin-right: 1rem;
    color: #919191;
}

.mitarbeiterEditSubmit:hover svg{
    color: green;
    
    
}


.mitarbeiterEditSubmit:hover{
   cursor: pointer;
}

.mitarbeiterInfoSelect{
    border-style: none;
    padding: .4rem;
    border-radius: 10px;
}