.kürzelWrapper {
    width: 100%;
    display: flex;
    background-color: #F2F3F5;
    min-height: 100%;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}

.kürzelHeader {
    width: 100%;
    display: flex;
    justify-content: right;
    padding: 1rem;
    padding-right: 2rem;
}

.kürzelButtonBox button {
    border: none;
    background-color: #F2F3F5;
    font-size: 1.2rem;
}

.kürzelButtonBox button:hover {
    cursor: pointer;
    font-size: 1.22rem;
}

.kürzelButtonBox{
    
}

.addKürzelBox{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 2;
    width: 60rem;
    height: 6rem;
    position: fixed;
    left: calc(50vw - 30rem);
    top: calc(20rem - 2.5rem);
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 10px;
    transition: linear;
}

.addKürzelBox div button:hover{
    cursor: pointer;
}

.blurrKürzel{
    z-index: 1;
    opacity: 0.3;
    min-height: 100vh;
    min-width: 100vw;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
}

.kürzelListe {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 70%;
}

.kürzelBox {
    border: 1px solid #b1b1b1;
    background-color: white;
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    justify-content: space-between;
    border-radius: 5px;
}


.kürzelBox:hover .kürzelInfoBox {
    display: none;
}

.hoverBox {
    display: none;
}

.kürzelBox:hover {
border: 1px solid #b1b1b1;
background-color: white;
width: 100%;
height: 10%;
display: flex;
align-items: center;
justify-content: space-between;
border-radius: 5px;
cursor: pointer;
}


.kürzelBox:hover .hoverBox {
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
}

.kürzelBox:hover .hoverBox .hoverBoxContent{
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.addEditBox{
    z-index: 2;
    width: 60rem;
    height: 6rem;
    position: fixed;
    left: calc(50vw - 30rem);
    top: calc(50vh - 3rem);
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 10px;
    transition: linear;
    display: flex;
    flex-direction: column;
}