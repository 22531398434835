.locationBox{
    padding: 2rem;
    margin-top: 3rem;
    background-color: white;
    border: 1px solid;
    justify-content: center;
    border-radius: 5px;
    width: 50vw;
}

.locationBox:hover{
    background-color: #ebebeb;
}

.locationBox a{
    text-decoration: none;
    color: black;
    height: 100%;
    min-width: 100%;
}

.locationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F2F3F5;
    min-height: 100vh;
    min-width: 100vw;
}