@media print{
    .calendarNav{
        display: none !important;
    }
    .calendarDropdownDate{
        display: none !important;
    }
    .navButton{
        display: none !important;
    }
    .calendarNavOptions{
        display: none !important;
    }
    .calendarNavDateWrappper{
        display: none !important;
    }
    .calendarDropdownContent{
        display: none !important;
    }
    .calendarDropdownOption{
        display: none !important;
    }
    
}

.calendarNav{
    display: flex;
    width: 100vw;
    height: 4rem;
    position: fixed;
    height: 65px;
    z-index: 2; 
    align-items: center;
    gap: 1rem;
    background-color: #F2F3F5;
}

.navButton {
    font-size: 3rem;
    margin-left: .25rem;
    margin-bottom: 2rem;
}

.navButton:hover {
    cursor: pointer;
}

.calendarNavOptions{
    display: flex;
    align-items: center;
    justify-content: left;
    width: 70%;
    font-size: 1.1rem;
    padding-bottom: 1rem;
}

.calendarDropdownUser{
    position: relative;
    display: inline-block;
    margin-left: 4rem;  
}

.calendarDropdownContent{
    display: none;
    position: absolute;
    background-color: #ffff;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.calendarDropdownOption{
    color: black;
    padding: 1rem 1.25rem;
    text-decoration: none;
    display: block;
}

.calendarDropdownOption:hover{
    background-color: #f1f1f1;
}

.calendarDropdownUser:hover .calendarDropdownContent{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.dropButton{
background-color: #202020;
  color: white;
  padding: 1rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  display: inline-block;
}

.calendarDropdownDate{
    font-size: 1.1rem;
    padding-bottom: 1rem;
    width: 100%;
}

.calendarDropdownDate input:hover{
   cursor: pointer;
}

.calendarNavDateWrappper{
    display: flex;
    align-items: center;
    width: 20%;
}

