

*{
    box-sizing: border-box;
    margin: 0;
}

body {
    margin: 0;
    min-width: 100%;
    display: inline-block;
    font-family: Helvetica;
}

html{
    margin: 0;
    padding: 0;
    overflow-x: scroll;
    display: inline-block;
}

.container {
    margin-top: 4.5rem;
    text-align: center;
    display: inline-block;
    min-width: 100vw;
    height: 100vh;
    min-height: 100vh;
}