@media print{
   #root{
    display: block;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
   }

   body{
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    display: block !important;
   }

   .container{
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
   }

   .calendarContainer{
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
   }

   .calendarNav{
    display: none !important;
   }

   .topDiv{
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
   }

   .calendarWrapper{
    display: flex !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
   }

   .calendarBox{
    display: block !important;
    margin: 0 0 0 65px !important;
    padding: 0 !important;
    width: 100% !important;
    min-height: 100vh !important;
   }

   .calendarDayWrapper{
    gap: 8px !important;
    width: 100% !important;
    page-break-after: always !important;
    max-height: 100vh !important;
   }

   .dayTitle{
    font-size: 12px !important;
    width: 100% !important;
    margin-top: 4px !important;
   }

   .calendarHourWrapper{
    width: 100% !important;
    gap: 10px !important;
    height: auto !important;
   }

   .calendarDay{
    gap: 2px  !important;
    height: 100% !important

   }

   .userTitle{
    font-size: 12px !important;
   }

   .calendarDayHour{
    height: 83px !important;
    margin: 0 !important;
    border-radius: 10px !important;
    gap: 0 !important;
   }

   .calendarDayQuarter{
   }

   .calendarDayNote{
    padding: 0 0 0 4px !important;
    font-size: 11px !important;
   }

   .textQuarter{
    font-size: 11px !important;
   }

   .raumDiv{
    display: none !important;
   }

   .calendarDayHourSeperator{
    opacity: 32%;
   }

   .calendarDayAddUser{
    display: none !important;
   }

   .calendarSidebar{
    position: fixed !important;
    margin: 39px 0 0 0 !important;
    padding: 0 0 0 .5rem !important;
    height: 100% !important;
    gap: 0 !important;
   }

   .calendarTime{
    height: 87px !important;
    margin: 0 !important;
    padding: 0 !important;
   }

   .time{
    font-size: 12px !important;
    margin: 0 !important;
    padding: 38px 4px 0 0 !important;
   }

   .calendarTimeQuarterBox{
    gap: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
   }

   .calendarTimeQuarter{
    margin: 0 !important;
    font-size: 11px !important;
   }
   

}