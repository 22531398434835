.calendarContainer {
    min-width: 100vw;
    height: 100vh;
}

.calendarWrapper {
    justify-content: left;
    display: flex;
    gap: 2rem;
    width: 100%;
    background-color: #F2F3F5;
    min-height: 100vh;
    min-width: 100vw;
}



/*-----------Zeit-Sidebar-----------------*/

.calendarSidebar {
    margin-left: 1rem;
    margin-top: 5rem;
    display: flex;
    align-content: center;
    flex-direction: column;
    background-color: #F2F3F5;
    min-height: 100vh;
}

.calendarTime {
    height: 7.5rem;
    display: flex;
    justify-content: space-between;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.time {
    font-weight: bold;
    color: rgba(78, 78, 78, 0.911);
    padding-top: 2.8rem;
    font-size: 1.5rem;
}

.calendarTimeQuarterBox {
    display: flex;
    flex-direction: column;
    gap: .1rem;
}

.calendarTimeQuarter {
    padding: 0.25rem;
    color: #919191;
}

.calendarTimeQuarterSeperator {
    border: 1px solid;
    border-color: grey;
    opacity: 50%;
}

/*Hoehe einer Time Box ohne margin ist .6rem + 6px + 2rem

/*-----------Days-----------------*/



.calendarBox {
    margin-top: 6rem;
    display: flex;
    justify-content: left;
    gap: 2rem;
    /*padding top an dieser Stelle, dann aber auch bei calendarSidebar um die Seperator zu matchen*/
    align-items: flex-start;
}

.calendarDayWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.calendarHourWrapper {
    display: flex;
    justify-content: left;
    gap: 1rem;

}

.dayTitle {
    font-size: 1.25rem;
}

.dayTitleCurr {
    font-size: 1.25rem;
    background-color: #6f6f6f;
    color: white;
    border-radius: 5px;
    padding-left: 1rem;
    padding-right: 1rem;
}

.calendarDayAddUser {
    font-size: 20px;
    border-radius: 5px;
    border: 1px solid;
    border-color: grey;
    opacity: 50%;
    height: 1.5%;
    padding: .2rem .3em;
    cursor: pointer;
}

.calendarDay {
    display: flex;
    flex-direction: column;
}

.userTitle {
    font-size: 1rem;
    padding-right: 1.4rem;
}

.calendarDayHour {
    height: 7.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #ffffff;
    margin-top: .5rem;
    margin-bottom: .5rem;
    gap: .1rem;

}

.calendarDayHour:hover {
    box-shadow: 3px 4px #d8d6d6;
    cursor: pointer;
}



.calendarDayQuarter {
    display: flex;
    border: 1px solid;
    border-color: white;
    max-width: 10rem;
}

.calendarDayNote {
    width: 10rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    text-align: left;
    padding-left: 0.5rem;
    white-space: nowrap;
    overflow-x: hidden;
}

.hasTermin {
    background-color: hwb(120 53% 9%);
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.hasTerminConsec {
    background-color: hsl(120, 100%, 79%);
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.calendarDayQuarter:hover {
    border: 1px solid;
    border-radius: 5px;
    border-color: hsl(0, 0%, 71%);
    box-shadow: 2px 2px #d8d6d6;
}

.calendarDayQuarter:hover .textQuarter {
    color: #5f5f5f;
}

.calendarDayHourSeperator {
    border: 1px solid;
    border-color: #919191;
    opacity: 0%;
}

.textQuarter {
    padding: calc(0.25rem - 1px) 0.25rem;
    color: #b6b6b6;
}


@keyframes zoom {
    from {
        opacity: 0%;
    }

    to {
        opacity: 100%;
    }
}

@keyframes blurr {
    from {
        opacity: 0%;
    }

    to {
        opacity: 0.5;
    }
}


.promptBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 2;
    width: 40rem;
    position: fixed;
    left: calc(50vw - 20rem);
    top: calc(20rem - 10rem);
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 10px;
    transition: linear;
    animation: zoom ease-out .5s;
    height: calc(100vh - 10rem);
    max-height: 100%;
    overflow: scroll;
}

.blurr {
    z-index: 1;
    opacity: 0.5;
    height: 100vh;
    width: 100vw;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    animation: blurr 1s;
}

.promptTop {
    display: flex;
    justify-content: space-between;
}

.promptBox .promptButtonWrapper {
    display: flex;
    justify-content: space-between;
    gap: .3rem;
}

.promptBox .closePrompt {
    min-width: 2rem;
    max-width: 2rem;
    min-height: 2rem;
    max-height: 2rem;
    border: none;
    padding-left: .7rem;
    padding-right: .7rem;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    font-size: 1rem;
}

.promptBox .submitPrompt {
    min-width: 2rem;
    max-width: 2rem;
    min-height: 2rem;
    max-height: 2rem;
    border: none;
    padding-left: .7rem;
    padding-right: .7rem;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    font-size: 1rem;
}

.promptBox .changePrompt {
    border: none;
    background-color: white;
    text-align: center;
    display: inline-block;
}
.promptBox .changePrompt svg{
   width: 1.6vw;
}

.promptBox .submitPrompt:hover {
    cursor: pointer;
    background-color: green;
    color: #ffffff;
}

.promptBox .closePrompt:hover {
    cursor: pointer;
    background-color: #BD0909;
    color: #ffffff;
}

.promptBox .changePrompt svg:hover {
    cursor: pointer;
    width: 1.7vw;
}

.promptSeperator {
    border: 1px solid;
    opacity: .1;
    border-color: #919191;
    width: 100%;
}

.promptWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.promptContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    height: 100%;
}

.promptRow {
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    gap: 1rem;
    width: 100%;
}

.promptInfoTime {
    display: flex;
    width: auto;
    gap: 1rem;
}

.promptLable {}

.promptValue {}



.promptInfoText {
    margin-left: 20%;
    height: 10rem;
    overflow-y: auto;
    width: 100%;
}

.promptInfoText textarea{
    width: 100%;
    height: 100%;
    resize: none;
    background-color:hsl(0, 0%, 92%);
    border: none;
    border-radius: 5px;
}

.patientInfoValue select{
    border-radius: 5px;
    border: none;
    background-color: white;
    font-size: 1rem;
}


.deleteConfirm{
    z-index: 3;
    left: calc(50vw - 20rem);
    top: calc(20rem - 10rem);
    padding: 1rem;
    border-radius: 10px;
    transition: linear;
    animation: zoom ease-out .5s;
    height: auto;
}

.searchPatient{
    border-radius: 15px;
    border: none;
    background-color: #F2F3F5;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.calendarDropdownLocation select{
   border: none;
   font-size: 1.1rem;
   background-color: #F2F3F5;
}



.loaderLogo{
    font-family: 'Genos', sans-serif;
    font-size: 4rem;
    text-align: center;
}
