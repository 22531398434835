.patientWrapper {
    display: flex;
    flex-direction: column;
    min-width: 100vw;
    min-height: 100vh;
    padding: 1rem;
    background-color: #F2F3F5;
    gap: 1rem;
    align-items: center;
    position: relative;
    width: 100%;
}

.patientWrapperTitel {
    width: 100%;
    min-width: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    gap: 2rem;
}

.patientSearch{
    width: 60%;
    align-items: center;
    display: flex;
}

.patientSearch input{
    border-radius: 20px;
    border: none;
    width: 100%;
    height: 1.5rem;
    padding: 1rem;
}

.patientAddButtonWrapper{
    align-items: center;
    display: flex;
    height: 100%;
    width: 20%;
}

.patientWrapperIcon{
    vertical-align: middle;
    align-items: center;
    display: flex;
    justify-content: left;
    width: 20%;
}

.patientWrapperIcon h1{
    font-size: max(1.7vw, 20px)
}

.patientWrapperIcon svg{
    vertical-align: middle;
    min-width : 2rem;
    width: 3vw;
    margin-right: 1rem;
    
}

.patientAddButton{
    font-size: 1rem;
    background-color: transparent;
    border: 1px solid;
    border-color: #F2F3F5;
    position: fixed;
    right: 1rem;
}



.patientAddButton span{
    margin-right: 1rem;
}

.patientAddButton svg:hover{
    cursor: pointer;
    color: green;
}

.patientAddButton svg{
    width: 1.5vw;
    vertical-align: middle;   
    min-width : 2rem;
}


.patientBox {
    border-radius: 10px;
    background-color: white;
    height: 7vh;
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    gap: 1rem;
    width: 60vw;
}



.patientBox:hover {
    cursor: pointer;
    border: 1px solid;
    border-radius: 5px;
    border-color: #b6b6b6;
    box-shadow: 2px 2px #d8d6d6;
}

@keyframes bottomUp {
    from {
        top: 100%;
        transform: translateX(-50%);
    }

    to {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

@keyframes slide-down {
    from {
        top: 50%;
        transform: translate(-50%, -50%);
    }

    to {
        top: 100%;
        transform: translateX(-50%);
    }
}

@keyframes blurr{
    from{opacity: 0%;}
    to{opacity: 0.4;}
}

@keyframes deBlurr{
    from{opacity: 0.4;}
    to{opacity: 0;}
}

.patientInfo {
    z-index: 2;
    background-color: white;
    animation: bottomUp .5s;
    animation-fill-mode: forwards;
    min-height: 35rem;
    height: 80vh;
    min-width: 45vw;
    max-width: 100vw;
    position: fixed;
    left: 50%;
    padding: 1rem;
    border-radius: 20px;
    transition: opacity 0.3s ease-out;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.patientInfo.hide {
    opacity: 0;
}

.blurrB {
    z-index: 1;
    opacity: 0.4;
    height: 100vh;
    width: 100vw;
    background-color: black;
    position: fixed;
    top: 0;
    left: 0;
    animation: blurr 1s forwards;
}

.blurrB.closing{
    animation: deBlurr 1s forwards;
}

.patientInfo.closing {
    animation-name: slide-down;
}

.patientInfoHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.patientInfoHeader button{
    min-width: 2rem;
    max-width: 2rem;
    min-height: 2rem;
    max-height: 2rem;
    border: none;
    padding-left: .7rem;
    padding-right: .7rem;
    border-radius: 50%;
    font-size: 1rem;   
}

.closeButton:hover{
    cursor: pointer;
    background-color:#BD0909;
    color: #ffffff;
}

.patientInfoContent{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
}

.patientInfoRow{
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
     gap: 1rem;
     width: 100%;
}

.patientInfoValue{
    font-size: .9rem;
}

.patientInfoKrankenkasse{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 1rem;
}

.patientInfoKontaktdaten{
display: flex;
flex-direction: column;
text-align: left;
gap: 1rem;
}

.patientRowSeperator{
    border: 1px solid;
    opacity: .1;
    border-color: #919191;
    width: 100%;
}

.patientInfoTextArea{
    margin-top: 1rem;
    text-align: left;
    height: 100%;
}

.patientInfoTextArea textarea{
    resize: none;
    width: 100%;
    height: 100%;
}

.patientInfoFooter{
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
}

.patientInfoButton{
    border: none;
    padding-left: .7rem;
    padding-right: .7rem;
    font-size: 1rem;
    background-color: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: white;
    text-align: center;
}

.patientInfoButton svg{
    vertical-align: middle;
    width: 1.5vw;
    margin-right: 1rem;
    color: #919191;
}

.patientInfoButton:hover{
    border: 1px solid;
    border-radius: 5px;
    border-color: #b6b6b6;
    box-shadow: 0px 2px #d8d6d6;
    cursor: pointer;
}

.patientEdit{
    z-index: 2;
    background-color: white;
    animation: bottomUp .5s;
    animation-fill-mode: forwards;
    min-height: 35rem;
    height: 80vh;
    min-width: 45vw;
    max-width: 100vw;
    position: fixed;
    left: 50%;
    padding: 1rem;
    border-radius: 20px;
    transition: opacity 0.3s ease-out;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.patientEdit.closing{
    animation-name: slide-down;
}

.patientInfoRow input{
    border: none;
    background-color:#e9e9e9;
    padding: .3rem;
    border-radius: 10px;
}

.patientInfoRow textarea{
    border: none;
    background-color:#e9e9e9;
    padding: .3rem;
    border-radius: 10px;
    resize:none;
    width: 100%;
    height: 100%;
}

.patientEditSubmit{
    border: none;
    padding-left: .7rem;
    padding-right: .7rem;
    font-size: 1rem;
    background-color: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: white;
}

.patientEditSubmit svg{
    vertical-align: middle;
    width: 1.5vw;
    margin-right: 1rem;
    color: #919191;
}

.patientEditSubmit:hover svg{
    color: green;
    
    
}
.patientEditSubmit:hover{
   cursor: pointer;
}

.patientInfoHeaderButtonWrapper{
    display: flex;
    gap: 1rem;
    text-align: center;
    align-items: center;
}

.deleteButton{
    background-color: white;
}

.deleteButton svg{
    width: 1.5vw;
    align-self: center;
}

.deleteButton svg:hover{
    width: 1.6vw;
}
