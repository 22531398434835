.rezeptWrapper {
    z-index: 2;
    background-color: #F2F3F5;
    animation: bottomUp .5s;
    animation-fill-mode: forwards;
    min-height: 35rem;
    height: 80vh;
    min-width: 45vw;
    max-width: 100vw;
    position: fixed;
    left: 50%;
    padding: 1rem;
    border-radius: 20px;
    transition: opacity 0.3s ease-out;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: left;
    overflow: auto;
    scrollbar-color: rgb(210,210,210) rgb(46,54,69) !important;
    scrollbar-width: thin !important;
}

.rezeptWrapper.closing {
    animation-name: slide-down;
}

@keyframes slide-down {
    from {
        top: 50%;
        transform: translate(-50%, -50%);
    }

    to {
        top: 100%;
        transform: translateX(-50%);
    }
}

@keyframes bottomUp {
    from {
        top: 100%;
        transform: translateX(-50%);
    }

    to {
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.rezepteBox{
    display: flex;
    background-color: white;
    min-width: 100%;
    height: 3rem;
    text-align: center;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;
   
}

.rezeptHeader{
    display: flex;
    justify-content: space-between;
}

.rezeptButtonClose{
    display: flex;
    gap: .4rem;
    align-items: center;
}

.rezeptEditButton{
    border-radius: 50%;
    max-width: 2rem;
    min-width: 2rem;
    min-height: 2rem;
    max-height: 2rem;
    border: none;
}

.rezeptEditButton svg{
width: 1.5rem;

}

.closeButton{
    min-width: 2rem;
    max-width: 2rem;
    min-height: 2rem;
    max-height: 2rem;
    border: none;
    padding-left: .7rem;
    padding-right: .7rem;
    border-radius: 50%;
    font-size: 1rem;
}

.rezeptBoxExpand{
    border: none;
    background-color: white;
    min-width: 2rem;
    max-width: 2rem;
    min-height: 2rem;
    max-height: 2rem;
    
}

.rezeptBoxExpand svg{
    vertical-align: middle;
    width: 1.5vw;
    margin-right: 1rem;
    color: #919191;
}

.rezeptBoxExpand svg:hover{
    cursor: pointer;
    color: black;
}

.rezeptButtonEdit{

}

.rezeptInfo{
    display: flex;
    background-color: white;
    min-width: 100%;
    align-items: left;
    padding: 2px;
    border-top: 1px solid;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 0px 0px 10px 10px;
    border-color: #b6b6b6;
    animation: ease-in 1s;
    flex-direction: column;
    animation: slideDown .2s forwards ease-out;
    
}

@keyframes slideDown {
    from{
        height: 0;
        overflow: hidden;
    }to{
        height: 25rem;
        overflow: hidden;
        
    }
}

.rezeptInfo.close{
    animation: slideUp .2s forwards ease-in;
}

@keyframes slideUp {
    from{
        overflow: hidden;
        height: 25rem;
       
    }to{
        height: 0;
        min-height: 0;
        overflow: hidden;   
        padding-top: 0;
        border-top: none;
       
    }
}

.rezeptInfoNote{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 1rem;
    height: 100%;
    overflow: auto;
}

.rezeptInfoFooter{
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid;
    border-color: #b6b6b6;
}

.rezeptInfoButton{
    border: none;
    padding-left: .7rem;
    padding-right: .7rem;
    font-size: 1rem;
    background-color: white;
    border-radius: 10px;
    border: 1px solid;
    border-color: white;
    display: flex;
    justify-content: center;
    height: 3rem;
    align-items: center;   
}


.rezeptInfoButton svg{
    vertical-align: middle;
    width: 1.5vw;
    margin-right: 1rem;
    color: #919191;
}


.rezeptInfoButton:hover{
    cursor: pointer;
}

.rezeptInfoSVG{
  
  display: flex;
}

.written{
    visibility: hidden;
}

.rezeptInfoButton:hover .written{
    visibility: visible;
}

