.messageWrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 1rem;
    background-color: #F2F3F5;
}

.messageBox{
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    gap: 2rem;
    font-size: 2rem;
}

.messageBox textarea{
resize: none;
width: 40rem;
height: 150px;
}