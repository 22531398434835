.home{
    min-width: 100vw;
    display: flex;
    background-color: #F2F3F5;
    min-height: 100vh;
}

.issueButtonAddBox{
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.issueButtonAddBox svg{
    width: 1.5rem;
}

.issueButtonAddBox button{
    background-color: #F2F3F5;
    border: none;
}

.issueButtonAddBox button:hover{
    cursor: pointer;
}

.issueBox{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5rem;
    gap: 2rem;
    height: 100%;
}

.issueWrapper{
    width: 100%;
    height: 20rem;
    flex-direction: column;
    display: flex;
    background-color: white;
    gap: 1rem;
    padding: 1rem;
}

.issueHeader{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.issueDelete{
    min-width: 6rem;
    max-width: 6rem;
    min-height: 2rem;
    max-height: 2rem;
    border: none;
    padding-left: .7rem;
    padding-right: .7rem;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    font-size: 1rem;
    background-color: white;
}

.issueDelete:hover {
    cursor: pointer;
    color: red;
}

.issueEdit{
    min-width: 6rem;
    max-width: 6rem;
    min-height: 2rem;
    max-height: 2rem;
    border: none;
    padding-left: .7rem;
    padding-right: .7rem;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    font-size: 1rem;
    background-color: white;
}

.issueEdit:hover{
    cursor: pointer;
    color: rgb(197, 143, 26);;
}

.issueText{
    width: 100%;
    height: 100%;
    overflow-wrap: break-word;
    overflow-y:scroll;
    text-align: start;
}

.issueSeperator {
    border: 1px solid;
    border-color: grey;
    opacity: 50%;
}


.issueAddWrapper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 2;
    width: 80%;
    position: fixed;
    left: calc(50vw - 40%);
    top: calc(50vh - 20rem);
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 10px;
    transition: linear;
    animation: zoom ease-out .5s;
    height: 40rem;
}

.issueAddHeader{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttonWrapper{
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.issueAddButton{
    min-width: 2rem;
    max-width: 2rem;
    min-height: 2rem;
    max-height: 2rem;
    border: none;
    padding-left: .7rem;
    padding-right: .7rem;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    font-size: 1rem;
}


.issueAddSubmit:hover{
    background-color: green;
    cursor: pointer;
    color: white;
}

.issueAddClose:hover{
    background-color: #BD0909;
    cursor: pointer;
    color: white;
}

.issueAddText{
    width: 100%;
    overflow-y: auto;
    height: 100%;
}

.issueAddText textarea{
    width: 100%;
    overflow-y: auto;
    height: 100%;
    resize: none;
    border-radius: 10px;
    border: none;
    background-color: #F2F3F5;
    padding: .4rem;
}