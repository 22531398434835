/*fonts*/
@import url('https://fonts.googleapis.com/css2?family=Genos:wght@100;300&display=swap');
/************/

@media print{
    nav{
        display: none !important;
    }
    .nav{
        display: none !important;
    }
    .navContent{
        display: none !important;
    }
    .logOff{
        display: none !important;
    }
}

.nav {
    background-color: #202020;
    color: white;
    display: flex;
    justify-content:space-between;
    padding: 0 1rem;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 10; 
    gap: 2rem;
}



.navContent{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: .2rem;
}

.logOff{
    display: flex;
}

.logOff svg{
    width: 1.3vw;
    vertical-align: middle;
}

.logOff:hover{
    cursor:pointer;
}

.nav li{
   height: 100%;
   display: flex;
   align-items: center;
   padding: 0 1rem 0;
   font-size: 1.2rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    padding: .55rem;
}

.nav li.active{
    background-color: #555;
    
}

.navContent li:hover{
    background-color: #777;
    transition: 1s;
    
}

.nav-logo{
    font-family: 'Genos', sans-serif;
    font-size: 2.5rem;
}
