.rezeptAdd{
z-index: 3;
background-color: #F2F3F5;
animation: bottomUp .5s;
animation-fill-mode: forwards;
min-height: 35rem;
height: 80vh;
min-width: 45vw;
max-width: 100vw;
position: fixed;
left: 50%;
padding: 1rem;
border-radius: 20px;
transition: opacity 0.3s ease-out;
display: flex;
flex-direction: column;
gap: 1rem;
text-align: left;
overflow: auto;
scrollbar-color: rgb(210,210,210) rgb(46,54,69) !important;
scrollbar-width: thin !important;
}

.rezeptAddInfoHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.printButton{
    width: 1.7vw;
    border: none;
    vertical-align: middle;
    background-color: #F2F3F5;
    margin-right: .4rem;
}

.printButton :hover{
    cursor: pointer;
}

.patientInfoTherapieSelect select{
border-radius: 6px;
border-style: none;
}