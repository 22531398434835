.profilWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F2F3F5;
    height: 100%;
    width: 100%;
    }
    
    .profilContainer{
    margin-top: -5%;
    width: 80vw;
    height: 90%;
    background-color: white;
    border-radius: 20px;
    padding-top: 1rem;
    color: #535353;
    display: flex;
    flex-direction: column;
    }
    
    .profilContainerHeader{
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding-top: .5rem;
    padding-left: 5%;
    padding-right: 1rem;
    margin-bottom: 1rem;
    }

    .profilContainerButtons{
        display: flex;
        width: 10%;
        height: 2rem;
        gap: 1rem;
        justify-content: space-evenly;
    }

    .profilEditButton{
        background-color: white;
        border: none;
        width: 100%;
    }

    .profilEditButton svg{
        height: 100%;
        width: 100%;
    }

    .profilEditButton svg:hover{
        cursor: pointer;
    }
    
    .profilContainerBody{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 2rem;
    gap: 1.4rem;
    }

    .profilContainerInfo{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        gap: 1rem;
    }

    .profilContainerInfoColumn{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 50%;
        width: 100%;
        gap: 1.4rem;
        padding: .5%;
    }

    .profilContainerRow{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 5%;
        text-align: center;
    }

    .profilContainerRowValue select{
        border: none;
        font-size: 1rem;
        background-color: white;
    }

    .profilContainerRowSeperator{
        border: 1px solid;
        opacity: .1;
        border-color: #919191;
        width: 100%;
    }

   
    .profilContainerColumnSeperator{
        height: 100%;
        border: 1px solid;
        opacity: .1;
        border-color: #919191;
        width: 1px;
    }

    .profilContainerStats{
        width: 100%;
        height: 100%;
        border: 1px solid;
        opacity: .1;
        border-color: #919191;
    }

    .profilCloseButton{
        background-color: white;
        border: none;
        width: 8vw;
        padding: 0;
        border-radius: 20px;
    }

    .profilCloseButton svg{
        width: 100%;
        height: 100%;
        border-radius: 20px;
    }

    .profilCloseButton svg:hover{
        cursor: pointer;
        color: red;
        width: 1.7vw;
    }


    .profilSubmitButton{
        background-color: white;
        border: none;
        width: 8vw;
        padding: 0;
        border-radius: 20px;
    }

    .profilSubmitButton svg{
        width: 100%;
        height: 100%;
        border-radius: 20px;
    }

    .profilSubmitButton svg:hover{
        cursor: pointer;
        color: green;
        width: 1.7vw;
    }
    
   
    
    .promptBox .changePrompt svg:hover {
        cursor: pointer;
        width: 1.7vw;
    }